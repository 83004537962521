import React, { useEffect } from "react"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import {
  todayOrClosestDayInFutureInList,
  formatDate,
  nextEvent,
} from "../utils/util_funcs"

function Home() {
  const events = useSelector((state) => state.data.events)
  const loading = useSelector((state) => state.data.loading)
  const { t, i18n } = useTranslation()
  const isEe = i18n.language === "est"

  return (
    <div className="container flex flex-wrap">
      <div className="background-container">
        <div className="map mobile-hidden">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2706.115139086587!2d28.01772693561881!3d59.45007550787579!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x46943f3358f769ad%3A0x278ceb5705d0b18d!2z0KXRgNCw0Lwg0JrQsNC30LDQvdGB0LrQvtC5INC40LrQvtC90Ysg0JHQvtC20LjQtdC5INCc0LDRgtC10YDQuCDQs9C-0YDQvtC00LAg0J3QsNGA0LLQsC3QmdGL0Y3RgdGD0YM!5e1!3m2!1sru!2see!4v1729455482433!5m2!1sru!2see"
            height={300}
            width={300}
            style={{ border: 0, borderRadius: "20px" }}
            allowFullScreen=""
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
        <div className="card event-card is-size-4 is-size-5-mobile is-size-4-tablet is-size-4-desktop mobile-hidden">
          <div className="card-header">
            <p className="card-header-title">{t("closestEvent")}</p>
          </div>

          <div className="card-content">
            {loading ? (
              <></>
            ) : (
              <div className="content">
                <p>
                  <strong>{`${t("eventLabel")}: `}</strong>
                  {isEe
                    ? nextEvent(events).title_ee
                    : nextEvent(events).title_ru}
                </p>
                <p>
                  <strong>{`${t("date")}: `}</strong>
                  {formatDate(new Date(nextEvent(events).dateTime), "date")}
                </p>
                <p>
                  <strong>{`${t("time")}: `}</strong>
                  {formatDate(new Date(nextEvent(events).dateTime), "time")}
                </p>
                <p>
                  <strong>{`${t("dayOfWeek")}: `}</strong>
                  {formatDate(
                    new Date(nextEvent(events).dateTime),
                    "dayOfWeek"
                  )}
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="card event-card is-size-4 is-size-5-mobile is-size-4-tablet is-size-4-desktop mobile-visible pos-rel">
        <div className="card-header">
          <p className="card-header-title">{t("closestEvent")}</p>
        </div>

        <div className="card-content">
          {loading ? (
            <></>
          ) : (
            <div className="content">
              <p>
                <strong>{`${t("eventLabel")}: `}</strong>
                {isEe ? nextEvent(events).title_ee : nextEvent(events).title_ru}
              </p>
              <p>
                <strong>{`${t("date")}: `}</strong>
                {formatDate(new Date(nextEvent(events).dateTime), "date")}
              </p>
              <p>
                <strong>{`${t("time")}: `}</strong>
                {formatDate(new Date(nextEvent(events).dateTime), "time")}
              </p>
              <p>
                <strong>{`${t("dayOfWeek")}: `}</strong>
                {formatDate(new Date(nextEvent(events).dateTime), "dayOfWeek")}
              </p>
            </div>
          )}
        </div>
      </div>
      <div className="map mobile-visible">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2027.9781852674616!2d28.022074126664517!3d59.45011505134699!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x46943f3358f769ad%3A0x278ceb5705d0b18d!2z0KXRgNCw0Lwg0JrQsNC30LDQvdGB0LrQvtC5INC40LrQvtC90Ysg0JHQvtC20LjQtdC5INCc0LDRgtC10YDQuCDQs9C-0YDQvtC00LAg0J3QsNGA0LLQsC3QmdGL0Y3RgdGD0YM!5e0!3m2!1sru!2see!4v1730159105423!5m2!1sru!2see"
          height={300}
          allowFullScreen=""
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
        ></iframe>
      </div>
    </div>
  )
}

export default Home
