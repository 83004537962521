import axios from "axios"
import { createNotification } from "./util_funcs"

// Function to make a GET request
export const get = async (url, toast = null, params = {}) => {
  try {
    const response = await axios.get(url, { params })
    createNotification(
      toast,
      response.data.messageType,
      response.data.message
    )()
    return response.data
  } catch (error) {
    console.error("Error making GET request:", error)
    throw error
  }
}

// Function to make a POST request
export const post = async (url, toast = null, data = {}, params = {}) => {
  try {
    const response = await axios.post(url, data, params)
    createNotification(
      toast,
      response.data.messageType,
      response.data.message
    )()
    return response.data
  } catch (error) {
    console.error("Error making POST request:", error)
    throw error
  }
}

// Function to make a PUT request
export const put = async (url, toast = null, data = {}) => {
  try {
    const response = await axios.put(url, data)
    createNotification(
      toast,
      response.data.messageType,
      response.data.message
    )()
    return response.data
  } catch (error) {
    console.error("Error making PUT request:", error)
    throw error
  }
}

// Function to make a DELETE request
export const del = async (url, toast = null) => {
  try {
    const response = await axios.delete(url)
    createNotification(
      toast,
      response.data.messageType,
      response.data.message
    )()
    return response.data
  } catch (error) {
    console.error("Error making DELETE request:", error)
    throw error
  }
}
