export const createNotification = (toast, type, message) => {
  if (!toast) {
    return () => {}
  }
  return () => {
    switch (type) {
      case "INFO":
        toast.info(message)
        break
      case "SUCCESS":
        toast.success(message)
        break
      case "WARNING":
        toast.warn(message)
        break
      case "ERROR":
        toast.error(message)
        break
    }
  }
}

export function formatDate(date, type = null) {
  let language = localStorage.getItem("language")
  if (language === null) {
    language = "est"
  }
  let timeDelimiter = language === "est" ? "." : ":"
  const pad = (n) => (n < 10 ? "0" + n : n)
  const day = pad(date.getDate())
  const month = pad(date.getMonth() + 1)
  const year = date.getFullYear()
  const hours = pad(date.getHours())
  const minutes = pad(date.getMinutes())
  if (type === null) {
    return `${day}.${month}.${year} ${hours}${timeDelimiter}${minutes}`
  } else if (type === "date") {
    return `${day}.${month}.${year}`
  } else if (type === "time") {
    return `${hours}${timeDelimiter}${minutes}`
  } else if (type === "dayOfWeek") {
    const days = {
      est: [
        "Pühapäev",
        "Esmaspäev",
        "Teisipäev",
        "Kolmapäev",
        "Neljapäev",
        "Reede",
        "Laupäev",
      ],
      rus: [
        "Воскресенье",
        "Понедельник",
        "Вторник",
        "Среда",
        "Четверг",
        "Пятница",
        "Суббота",
      ],
    }
    return days[language][date.getDay()]
  }
}

export function nextEvent(events) {
  const now = new Date()
  const nextEvent = events
    .filter((event) => new Date(event.dateTime) > now)
    .sort(
      (a, b) => new Date(a.dateTime).getTime() - new Date(b.dateTime).getTime()
    )
  return nextEvent[0]
}
