import React from "react"
import { useSelector } from "react-redux"
import { formatDate } from "../utils/util_funcs"
import { useTranslation } from "react-i18next"

function Schedule() {
  const events = useSelector((state) => state.data.events)
  const { t, i18n } = useTranslation()
  const isEe = i18n.language === "est"
  return (
    <>
      <table className="table is-striped is-hoverable is-fullwidth is-size-3 is-size-5-mobile is-size-4-tablet is-size-3-desktop mobile-hidden">
        <thead>
          <tr>
            <th style={{ textAlign: "center" }}>{t("date")}</th>
            <th style={{ textAlign: "center" }}>{t("time")}</th>
            <th style={{ textAlign: "center" }}>{t("dayOfWeek")}</th>
            <th style={{ textAlign: "center" }}>{t("eventLabel")}</th>
          </tr>
        </thead>
        <tbody>
          {events.map((item, index) => {
            if (item.active) {
              return (
                <tr key={index}>
                  <td>{formatDate(new Date(item.dateTime), "date")}</td>
                  <td>{formatDate(new Date(item.dateTime), "time")}</td>
                  <td>{formatDate(new Date(item.dateTime), "dayOfWeek")}</td>
                  <td>{isEe ? item.title_ee : item.title_ru}</td>
                </tr>
              )
            } else {
              return <></>
            }
          })}
        </tbody>
      </table>
      <div className="container flex flex-wrap justify-center is-size-3 is-size-5-mobile is-size-4-tablet is-size-3-desktop mobile-visible">
        {events.map((item, index) => {
          return (
            <div key={index} className="card w-90 mt-2 mb-2">
              <div className="card-content">
                <div className="content">
                  <p>{formatDate(new Date(item.dateTime), "date")}</p>
                  <p>{formatDate(new Date(item.dateTime), "time")}</p>
                  <p>{formatDate(new Date(item.dateTime), "dayOfWeek")}</p>
                  <p className="has-text-weight-bold is-size-5">
                    {isEe ? item.title_ee : item.title_ru}
                  </p>
                </div>
              </div>
            </div>
          )
        })}
      </div>
    </>
  )
}

export default Schedule
