import React from "react"
import { createRoot } from "react-dom/client"
import { Provider } from "react-redux"
import { store } from "./app/store"
import App from "./App"
import reportWebVitals from "./reportWebVitals"
import "bulma/css/bulma.css"
import "react-toastify/dist/ReactToastify.css"
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"
import "./index.css"
import "./utils/translations"
const container = document.getElementById("root")
const root = createRoot(container)

root.render(
  <Provider store={store}>
    <App />
  </Provider>
)
