import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { API_PRAY_REQUEST } from "../utils/constants"
import { useNavigate } from "react-router"
import { toast } from "react-toastify"
import { post } from "../utils/axios_utils"
import { Comment } from "react-loader-spinner"

function PrayRequest() {
  const { t, i18n } = useTranslation()
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()
  const [formData, setFormData] = useState({
    prayType: "",
    names: "",
  })

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    })
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    setLoading(true)
    await post(API_PRAY_REQUEST, toast, JSON.stringify(formData), {
      headers: {
        "Content-type": "application/json",
        Accept: "application/json",
      },
    })
    setLoading(false)
    navigate("/")
  }
  return (
    <div className="mb-2">
      <div className="prayer-form-container">
        <h1 className="title has-text-centered">{t("prayRequest")}</h1>
        <form onSubmit={handleSubmit}>
          <div className="field">
            <label className="label is-size-4">{t("prayType")}</label>
            <div className="control">
              <div className="select">
                <select
                  name="prayType"
                  value={formData.prayType}
                  onChange={handleChange}
                  required
                >
                  <option value="">{t("select")}</option>
                  <option value="ALIVE">{t("forAlive")}</option>
                  <option value="DEAD">{t("forDead")}</option>
                </select>
              </div>
            </div>
          </div>

          <div className="field">
            <label className="label is-size-4">{t("names")}</label>
            <div className="control">
              <input
                className="input"
                type="text"
                name="names"
                value={formData.names}
                onChange={handleChange}
                required
              />
            </div>
          </div>

          <div className="field">
            <div className="control">
              <button
                type="submit"
                disabled={loading}
                className="button is-link is-size-5"
              >
                {loading ? (
                  <Comment
                    visible={true}
                    height="40"
                    width="40"
                    ariaLabel="comment-loading"
                    wrapperStyle={{}}
                    wrapperClass="comment-wrapper"
                    color="#000"
                    backgroundColor="#fff"
                  />
                ) : (
                  t("submit")
                )}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  )
}

export default PrayRequest
