import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  hasAccess: false,
}

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setAccess: (state, action) => {
      state.hasAccess = action.payload
    },
  },

  extraReducers: (builder) => {},
})

export const { setAccess } = authSlice.actions
export default authSlice.reducer
