import React, { useRef, useState } from "react"
import { FaUser, FaLock, FaEye, FaEyeSlash } from "react-icons/fa"
import { createNotification } from "../utils/util_funcs"
import { toast } from "react-toastify"
import Admin from "./Admin"
import { useDispatch, useSelector } from "react-redux"
import { setAccess } from "../app/reducers/authSlice"
import { post } from "../utils/axios_utils"
import { API_LOGIN } from "../utils/constants"

function Login() {
  const hasAccess = useSelector((state) => state.auth.hasAccess)
  const dispatch = useDispatch()
  const [isEyeOpen, setEyeOpen] = useState(false)
  const [payload, setPayload] = useState({
    username: "",
    password: "",
  })
  const handleChange = (event, payload, setPayload) => {
    setPayload({ ...payload, [event.target.name]: event.target.value })
  }
  function changeEyeState() {
    setEyeOpen(!isEyeOpen)
  }
  async function loginAttempt(e) {
    e.preventDefault()
    const data = await post(API_LOGIN, toast, payload, {
      headers: {
        "Content-type": "application/json",
        Accept: "application/json",
      },
    })
    if (data.messageType === "SUCCESS") {
      dispatch(setAccess(true))
    }
  }
  return (
    <>
      {hasAccess ? (
        <Admin />
      ) : (
        <div
          className="container is-flex is-justify-content-center is-align-items-center"
          style={{ minHeight: "100vh" }}
        >
          <div className="column is-half">
            <div className="box">
              <h1 className="title has-text-centered">Login</h1>
              <form>
                <div className="field">
                  <label className="label">Username</label>
                  <div className="control has-icons-left has-icons-right">
                    <span className="icon is-small is-left">
                      <FaUser />
                    </span>
                    <input
                      className="input"
                      type="Username"
                      placeholder="Username"
                      name="username"
                      required
                      onInput={(event) =>
                        handleChange(event, payload, setPayload)
                      }
                    />
                  </div>
                </div>

                <div className="field">
                  <label className="label">Password</label>
                  <div className="control has-icons-left has-icons-right">
                    <span className="icon is-small is-left">
                      <FaLock />
                    </span>
                    <input
                      className="input"
                      type={isEyeOpen ? "text" : "password"}
                      placeholder="Password"
                      name="password"
                      required
                      onInput={(event) =>
                        handleChange(event, payload, setPayload)
                      }
                    />
                    {isEyeOpen ? (
                      <span
                        onClick={changeEyeState}
                        className="icon is-small is-right"
                        style={{ pointerEvents: "initial" }}
                      >
                        <FaEye />
                      </span>
                    ) : (
                      <span
                        onClick={changeEyeState}
                        className="icon is-small is-right"
                        style={{ pointerEvents: "initial" }}
                      >
                        <FaEyeSlash />
                      </span>
                    )}
                  </div>
                </div>

                {/* Login Button */}
                <div className="field">
                  <div className="control">
                    <button
                      className="button is-primary is-fullwidth"
                      onClick={(e) => loginAttempt(e)}
                    >
                      Login
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default Login
