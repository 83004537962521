import React from "react"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router"
import { API_EVENTS, API_NEWS, API_NEWS_DELETE } from "../utils/constants"
import { del, get } from "../utils/axios_utils"
import { toast } from "react-toastify"
import { setEvents, setNews } from "../app/reducers/dataSlice"
import { formatDate } from "../utils/util_funcs"

function Admin() {
  const navigate = useNavigate()
  const news = useSelector((state) => state.data.news)
  const events = useSelector((state) => state.data.events)
  const dispatch = useDispatch()

  const setActive = async (id, entity) => {
    if (entity === "events") await get(API_EVENTS.SET_ACTIVE(id), toast)
    if (entity === "news") await get(API_NEWS.SET_ACTIVE(id), toast)
    dispatch(setEvents(await get(API_EVENTS.ALL)))
    dispatch(setNews(await get(API_NEWS.ALL)))
  }

  return (
    <div className="container">
      <h1 className="title has-text-centered mt-3">Admin</h1>
      <div className="columns">
        <div className="column is-half">
          <div className="box">
            <h2 className="title">News</h2>
            <button
              className="button is-small is-success"
              onClick={() => navigate("manage-news")}
            >
              Add
            </button>
            <table className="table is-fullwidth is-striped">
              <thead>
                <tr>
                  <th className="has-text-centered">Title</th>
                  <th className="has-text-centered">Actions</th>
                </tr>
              </thead>
              <tbody>
                {!!news.length &&
                  news.map((item, idx) => {
                    return (
                      <tr key={idx}>
                        <td>{item.title_ru}</td>
                        <td>
                          <button
                            className="button is-small is-info"
                            onClick={() => {
                              navigate("manage-news", {
                                state: { id: item.id },
                              })
                            }}
                          >
                            Edit
                          </button>
                          <button
                            className="button is-small is-danger ml-2"
                            onClick={async () => {
                              await del(API_NEWS.DELETE(item.id), toast)
                              dispatch(setNews(await get(API_NEWS.ALL)))
                            }}
                          >
                            Remove
                          </button>
                          {item.active ? (
                            <button
                              className="button is-small is-warning ml-2"
                              onClick={() => setActive(item.id, "news")}
                            >
                              Deactivate
                            </button>
                          ) : (
                            <button
                              className="button is-small is-success ml-2"
                              onClick={() => setActive(item.id, "news")}
                            >
                              Activate
                            </button>
                          )}
                        </td>
                      </tr>
                    )
                  })}
              </tbody>
            </table>
          </div>
        </div>

        {/* Right Table */}
        <div className="column is-half">
          <div className="box">
            <h2 className="title">Schedule</h2>
            <button
              onClick={() => {
                navigate("manage-events")
              }}
              className="button is-small is-success"
            >
              Add
            </button>
            <table className="table is-fullwidth is-striped">
              <thead>
                <tr>
                  <th className="has-text-centered">Event</th>
                  <th className="has-text-centered">Date</th>
                  <th className="has-text-centered">Actions</th>
                </tr>
              </thead>
              <tbody>
                {!!events.length &&
                  events.map((item, idx) => {
                    return (
                      <tr key={idx}>
                        <td>{item.title_ru}</td>
                        <td>{formatDate(new Date(item.dateTime))} </td>
                        <td>
                          <button
                            className="button is-small is-info"
                            onClick={() => {
                              navigate("manage-events", {
                                state: { id: item.id },
                              })
                            }}
                          >
                            Edit
                          </button>
                          <button
                            className="button is-small is-danger ml-2"
                            onClick={async () => {
                              await del(API_EVENTS.DELETE(item.id), toast)
                              dispatch(setEvents(await get(API_EVENTS.ALL)))
                            }}
                          >
                            Remove
                          </button>
                          {item.active ? (
                            <button
                              className="button is-small is-warning ml-2"
                              onClick={() => setActive(item.id, "events")}
                            >
                              Deactivate
                            </button>
                          ) : (
                            <button
                              className="button is-small is-success ml-2"
                              onClick={() => setActive(item.id, "events")}
                            >
                              Activate
                            </button>
                          )}
                        </td>
                      </tr>
                    )
                  })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Admin
