import React, { useEffect, useLayoutEffect, useState } from "react"
import FullFeatureEditor from "./FullFeatureEditor"
import { useDispatch, useSelector } from "react-redux"
import { Navigate, useLocation, useNavigate } from "react-router"
import UploadImage from "./UploadImage"
import Modal from "./Modal"
import { API_NEWS, FILE } from "../utils/constants"
import { get, post, put } from "../utils/axios_utils"
import { toast } from "react-toastify"
import ReactHintFactory from "react-hint"
import { setNews } from "../app/reducers/dataSlice"
const ReactHint = ReactHintFactory(React)

function ManageNews() {
  const hasAccess = useSelector((state) => state.auth.hasAccess)
  const location = useLocation()
  const isEdit = !!location?.state?.id
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [exploreFiles, setExploreFiles] = React.useState([])
  const [modalOpen, setModalOpen] = React.useState(false)
  const [html_ru, setHtmlRu] = useState("")
  const [html_ee, setHtmlEe] = useState("")
  const [formData, setFormData] = useState({
    title_ee: "",
    title_ru: "",
    description_ee: "",
    description_ru: "",
    preview: "",
  })

  const handleChange = (e) => {
    const { name, value } = e.target
    setFormData({
      ...formData,
      [name]: value,
    })
  }

  async function fetchData() {
    const data = await get(FILE.ALL)
    setExploreFiles(data)
  }

  async function postNews() {
    await post(
      isEdit ? API_NEWS.EDIT(location.state.id) : API_NEWS.CREATE,
      toast,
      JSON.stringify({ ...formData, content_ee: html_ee, content_ru: html_ru }),
      {
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
        },
      }
    )
    dispatch(setNews(await get(API_NEWS.ALL)))
    navigate("/admin")
  }

  useEffect(() => {
    if (isEdit) {
      const getNews = async () => {
        const data = await get(API_NEWS.BY_ID(location.state.id))
        setFormData({
          title_ee: data.title_ee,
          title_ru: data.title_ru,
          description_ee: data.description_ee,
          description_ru: data.description_ru,
          content_ee: setHtmlEe(data.content_ee),
          content_ru: setHtmlRu(data.content_ru),
          preview: data.preview,
        })
      }
      getNews()
    }
    fetchData()
  }, [])

  return (
    <>
      {hasAccess ? (
        <div className="container" style={{ "maxWidth": "900px" }}>
          <div className="field">
            <label className="label">Preview</label>
            <div className="control">
              <input
                type="text"
                name="preview"
                id=""
                onChange={handleChange}
                defaultValue={isEdit ? formData.preview : ""}
                className="input"
                data-rh="img"
              />
              <ReactHint
                events
                autoPosition
                onRenderContent={(target) => (
                  <img
                    style={{ "maxWidth": "100px", "maxHeight": "100px" }}
                    className="m-2"
                    src={target.value}
                  />
                )}
              />
            </div>
          </div>
          <div className="field">
            <label className="label">Title ru</label>
            <div className="control">
              <input
                type="text"
                name="title_ru"
                id=""
                onChange={handleChange}
                className="input"
                defaultValue={isEdit ? formData.title_ru : ""}
              />
            </div>
          </div>
          <div className="field">
            <label className="label">Title ee</label>
            <div className="control">
              <input
                type="text"
                name="title_ee"
                id=""
                onChange={handleChange}
                className="input"
                defaultValue={isEdit ? formData.title_ee : ""}
              />
            </div>
          </div>
          <div className="field">
            <label className="label">Description ru</label>
            <div className="control">
              <input
                type="text"
                name="description_ru"
                id=""
                onChange={handleChange}
                className="input"
                defaultValue={isEdit ? formData.description_ru : ""}
              />
            </div>
          </div>
          <div className="field">
            <label className="label">Description ee</label>
            <div className="control">
              <input
                type="text"
                name="description_ee"
                id=""
                onChange={handleChange}
                className="input"
                defaultValue={isEdit ? formData.description_ee : ""}
              />
            </div>
          </div>
          {isEdit ? (
            !!html_ru && (
              <FullFeatureEditor setHtml={setHtmlRu} html={html_ru} />
            )
          ) : (
            <FullFeatureEditor setHtml={setHtmlRu} html={""} />
          )}
          {isEdit ? (
            !!html_ee && (
              <FullFeatureEditor setHtml={setHtmlEe} html={html_ee} />
            )
          ) : (
            <FullFeatureEditor setHtml={setHtmlEe} html={""} />
          )}

          <div className="columns">
            <UploadImage />
            <div className="column is-one-third">
              <button onClick={postNews} className="button is-primary mt-2">
                {isEdit ? "Save" : "Create"}
              </button>
            </div>
            <div className="column is-one-third">
              <button
                className="button is-primary mt-2"
                onClick={() => {
                  setModalOpen(true)
                  fetchData()
                }}
              >
                Explore files
              </button>
              <Modal isActive={modalOpen} onClose={() => setModalOpen(false)}>
                <div className="columns is-multiline">
                  {exploreFiles.map((file) => (
                    <div key={file.id} className="column is-4">
                      <div className="card">
                        <div className="card-image">
                          <figure className="image is-4by3">
                            <img src={file.url} alt={`Image ${file.id}`} />
                          </figure>
                        </div>
                        <div className="card-content">
                          <div className="media">
                            <div className="media-content">
                              <p className="title is-4">{file.id + file.ext}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </Modal>
            </div>
          </div>
        </div>
      ) : (
        <Navigate to="/admin" />
      )}
    </>
  )
}

export default ManageNews
