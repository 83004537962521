import React, { useEffect, useState } from "react"
import { get, post } from "../utils/axios_utils"
import { API_EVENTS } from "../utils/constants"
import { toast } from "react-toastify"
import { useDispatch } from "react-redux"
import { setEvents } from "../app/reducers/dataSlice"
import { useLocation, useNavigate } from "react-router"

function ManageEvents() {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation()

  const isEdit = !!location?.state?.id
  const [formData, setFormData] = useState({
    title_ee: "",
    title_ru: "",
    dateTime: "",
  })

  const handleChange = (e) => {
    const { name, value } = e.target
    setFormData({
      ...formData,
      [name]: value,
    })
  }

  async function handleSubmit(e) {
    e.preventDefault()

    await post(
      isEdit ? API_EVENTS.EDIT(location.state.id) : API_EVENTS.CREATE,
      toast,
      JSON.stringify(formData),
      {
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
        },
      }
    )
    dispatch(setEvents(await get(API_EVENTS.ALL)))
    navigate("/admin")
  }

  useEffect(() => {
    if (isEdit) {
      const getEvent = async () => {
        const data = await get(API_EVENTS.BY_ID(location.state.id))
        setFormData({
          title_ee: data.title_ee,
          title_ru: data.title_ru,
          dateTime: data.dateTime,
        })
      }
      getEvent()
    }
  }, [])

  return (
    <div className="container">
      <div>
        {/* Title Input */}
        <div className="field">
          <label className="label">Title RU</label>
          <div className="control">
            <input
              className="input"
              type="text"
              name="title_ru"
              value={formData.title_ru}
              onChange={handleChange}
              placeholder="Enter title"
              required
            />
          </div>
        </div>
        <div className="field">
          <label className="label">Title EE</label>
          <div className="control">
            <input
              className="input"
              type="text"
              name="title_ee"
              value={formData.title_ee}
              onChange={handleChange}
              placeholder="Enter title"
              required
            />
          </div>
        </div>

        {/* DateTime Input */}
        <div className="field">
          <label className="label">Date and Time</label>
          <div className="control">
            <input
              className="input"
              type="datetime-local"
              name="dateTime"
              value={formData.dateTime}
              onChange={handleChange}
              required
            />
          </div>
        </div>

        {/* Submit Button */}
        <div className="field">
          <div className="control">
            <button
              className="button is-primary"
              onClick={(e) => handleSubmit(e)}
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ManageEvents
