import React, { useState } from "react"

const FileInput = ({ selectedFile, setSelectedFile }) => {
  return (
    <div className="column is-one-second mt-2">
      <div className="file has-name is-boxed">
        <label className="file-label">
          <input
            className="file-input"
            type="file"
            name="resume"
            onChange={(e) => setSelectedFile(e.target.files[0])}
          />
          <span className="file-cta">
            <span className="file-icon">
              <i className="fas fa-upload"></i>
            </span>
            <span className="file-label">Choose a file…</span>
          </span>
          {selectedFile && (
            <span className="file-name">{selectedFile.name}</span>
          )}
        </label>
      </div>
    </div>
  )
}

export default FileInput
