import React from "react"

const Modal = ({ isActive, onClose, children }) => {
  return (
    <div className={`modal ${isActive ? "is-active" : ""}`}>
      <div className="modal-background" onClick={onClose}></div>
      <div className="modal-card" style={{ "maxWidth": "600px" }}>
        <section className="modal-card-body">{children}</section>
        <footer className="modal-card-foot">
          <button className="button is-success" onClick={onClose}>
            Close
          </button>
        </footer>
      </div>
    </div>
  )
}

export default Modal
