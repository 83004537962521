import React, { useEffect, useState } from "react"
import {
  ContentState,
  convertFromHTML,
  convertToRaw,
  EditorState,
} from "draft-js"
import { Editor } from "react-draft-wysiwyg"
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"
import "draft-js/dist/Draft.css"
import draftToHtml from "draftjs-to-html"
import { useDispatch, useSelector } from "react-redux"

const editorStyle = {
  wrapperStyle: {
    margin: "0 auto",
    width: "100%",
    border: "1px solid #ddd",
    borderRadius: "8px",
    backgroundColor: "#f9f9f9",
  },
  toolbarStyle: {
    borderBottom: "1px solid #ddd",
    backgroundColor: "#f1f1f1",
    borderRadius: "8px 8px 0 0",
  },
  editorStyle: {
    color: "#000",
    minHeight: "300px",
    padding: "10px",
    backgroundColor: "#fff",
    border: "1px solid #ddd",
    borderRadius: "0 0 8px 8px",
    boxShadow: "0 1px 3px rgba(0,0,0,0.1)",
    outline: "none",
    cursor: "text",
  },
}

const FullFeatureEditor = ({ setHtml, html }) => {
  const [editorState, setEditorState] = useState(
    EditorState.createWithContent(
      ContentState.createFromBlockArray(convertFromHTML(html))
    )
  )

  useEffect(() => {
    setHtml(draftToHtml(convertToRaw(editorState.getCurrentContent())))
  }, [editorState, html])

  return (
    <div className="mt-2">
      <Editor
        defaultEditorState={editorState}
        onEditorStateChange={setEditorState}
        editorStyle={editorStyle.editorStyle}
        toolbarStyle={editorStyle.toolbarStyle}
        wrapperStyle={editorStyle.wrapperStyle}
        wrapperClassName="wrapper-class"
        editorClassName="editor-class"
        toolbarClassName="toolbar-class"
      />
    </div>
  )
}

export default FullFeatureEditor
