import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { useLocation, useParams } from "react-router"
import { setLoading, setNews } from "../app/reducers/dataSlice"
import { API_NEWS } from "../utils/constants"
import { get } from "../utils/axios_utils"

function NewsItem() {
  const location = useLocation()
  const { t, i18n } = useTranslation()
  const loading = useSelector((state) => state.data.loading)
  const dispatch = useDispatch()
  const lang = localStorage.getItem("language") || "est"
  const [news, setActualNews] = useState(location.state)
  const isEe = i18n.language === "est"

  useEffect(() => {
    const prepareNews = async () => {
      dispatch(setLoading(true))
      const api_news = await get(API_NEWS.ALL)
      dispatch(setNews(api_news))
      setActualNews(
        location.state
          ? location.state
          : api_news.find(
              (n) =>
                n.id === parseInt(window.location.pathname.split("/").pop())
            )
      )
    }
    prepareNews()
    dispatch(setLoading(false))
  }, [])

  return (
    <>
      {!loading && (
        <div>
          <h1 className="is-size-5" style={{ "color": "black !important" }}>
            {isEe ? news.title_ee : news.title_ru}
          </h1>
          <p className="is-size-5" style={{ "color": "black !important" }}>
            {isEe ? news.description_ee : news.description_ru}
          </p>
          <img
            src={news.preview}
            alt={`News Image ${news.preview.split("/").pop().split(".")[0]}`}
            style={{ width: "500px" }}
          />
          <div
            style={{ "color": "black !important" }}
            dangerouslySetInnerHTML={{
              __html: isEe ? news.content_ee : news.content_ru,
            }}
          ></div>
        </div>
      )}
    </>
  )
}

export default NewsItem
